// @flow

import * as React from 'react'
import { Spinner } from '@compeon-os/components'

import Layout from 'Layout'

import './Loading.css'

type TLoadingProps = {|
  message?: string
|}

const Loading = ({ message = 'Wird geladen' }: TLoadingProps) => (
  <Layout>
    <div className='Loading'>
      <Spinner size={38} isLoading />
      {message}
    </div>
  </Layout>
)

export default Loading
