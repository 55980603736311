// @flow

import * as React from 'react'

import type { TRequiredDocument } from 'modules/inquiry/types'

type TRequiredDocumentsProps = {
  requiredDocuments: TRequiredDocument[]
}

const RequiredDocuments = ({ requiredDocuments }: TRequiredDocumentsProps) => {
  if (requiredDocuments.length === 0) return null
  return (
    <ul>
      {requiredDocuments.map(({ name, optional }) => (
        <li key={name}>
          {optional && 'Optional: '}
          {name}
        </li>
      ))}
    </ul>
  )
}

export default RequiredDocuments
