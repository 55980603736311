import startsWith from 'lodash/startsWith'

const ALLOWED_EMAIL_USER_CHARS = Object.freeze(
  "abcdefg.hijklmnopqrstuvwxyz!#$%&'*/=?^_+-`{|}~0123456789".split('')
)

const NUMBER_OF_ALLOWED_EMAIL_USER_CHARS = ALLOWED_EMAIL_USER_CHARS.length

function validateEmailStructure (email) {
  return !!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function validateEmailUser (user) {
  const chars = new Set([...ALLOWED_EMAIL_USER_CHARS, ...user.split('')])
  return user.length &&
    !startsWith(user, '.') &&
    chars.size === NUMBER_OF_ALLOWED_EMAIL_USER_CHARS
}

function validateEmailHost (host) {
  return !(
    !host.includes('.') ||
    host.endsWith('-') ||
    host.endsWith('.') ||
    host.includes('..') ||
    host.includes('_') ||
    host.includes('~')
  )
}

function validateEmailAddress (email) {
  const lowercaseEmail = email.trim().toLowerCase()
  const parts = lowercaseEmail.split('@')
  const [user, host] = parts

  if (
    parts.length !== 2 ||
    !validateEmailStructure(lowercaseEmail) ||
    !validateEmailUser(user) ||
    !validateEmailHost(host)
  ) return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
}

export default validateEmailAddress
