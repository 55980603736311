// @flow

import type { TFile } from '@compeon-os/components'

import { DOCUMENT_UPDATE_STATUS } from '../actions'

const updateDocumentStatus = (document: TFile, status: string) => ({
  payload: {
    id: document.id,
    status
  },
  type: DOCUMENT_UPDATE_STATUS
})

export default updateDocumentStatus
