// @flow

import get from 'lodash/get'

import { REQUEST_ERROR, REQUEST_SUCCESS } from 'modules/api/state/actions'
import type { TApiAction } from 'modules/common/state/types'
import {
  SESSION_FETCH,
  SESSION_FETCH_CURRENT_USER,
  SESSION_FETCH_TAX_ACCOUNTANT_TOKEN,
  SESSION_FETCH_ACCESS_TOKEN
} from '../actions'

type TSessionState = {
  +authorized: ?boolean,
  +token: ?string,
  +user: ?Object
}

type TSessionAction = {
  payload: {
    token: string,
    user: Object,
    access_token: string,
    refresh_token: string,
    code: string
  }
} & TApiAction

const initialState: TSessionState = Object.freeze({
  authorized: null,
  authorizationToken: null,
  refreshToken: null,
  token: null,
  user: {}
})

const reducer = (state: TSessionState = initialState, { meta, payload, type }: TSessionAction) => {
  const metaType = get(meta, 'type')

  if (metaType === SESSION_FETCH_ACCESS_TOKEN) {
    switch (type) {
      case REQUEST_SUCCESS: {
        return {
          ...state,
          token: payload.access_token,
          refreshToken: payload.refresh_token,
          authorized: true
        }
      }

      case REQUEST_ERROR: {
        return {
          ...state,
          token: null,
          refreshToken: null,
          authorized: false
        }
      }

      default: return state
    }
  }

  if (metaType === SESSION_FETCH_CURRENT_USER) {
    switch (type) {
      case REQUEST_SUCCESS: {
        return {
          ...state,
          user: payload
        }
      }

      default: return state
    }
  }

  if (metaType === SESSION_FETCH_TAX_ACCOUNTANT_TOKEN) {
    switch (type) {
      case REQUEST_SUCCESS: {
        return {
          ...state,
          authorizationToken: payload.code,
          isTaxAccountant: true
        }
      }

      default: return state
    }
  }

  if (metaType === SESSION_FETCH) {
    switch (type) {
      case REQUEST_SUCCESS: {
        const role = get(payload, 'user.role')
        const authorized = role === 'customer' || role === 'multiplier'

        return {
          ...state,
          authorized,
          token: payload.token,
          user: payload.user || {}
        }
      }

      case REQUEST_ERROR: {
        return {
          ...state,
          authorized: false
        }
      }

      default: return state
    }
  }

  return state
}

export default reducer
