// @flow

import type { TFile } from '@compeon-os/components'

import type { TDocumentProgress } from '../../types'
import { DOCUMENT_UPDATE_PROGRESS } from '../actions'

const updateDocumentProgress = (document: TFile, { progress }: TDocumentProgress) => ({
  payload: {
    id: document.id,
    progress
  },
  type: DOCUMENT_UPDATE_PROGRESS
})

export default updateDocumentProgress
