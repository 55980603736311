// @flow

import get from 'lodash/get'
import type { TFile } from '@compeon-os/components'

const formatUploaderName = ({ firstName = '', lastName = '' }: Object) => (
  `${firstName} ${lastName}`
)

const transformDocumentFromApi = (document: Object): TFile => ({
  contentType: document.contentType,
  id: document.id,
  title: document.title,
  status: document.progress,
  uploadDate: document.createdAt,
  uploaderName: formatUploaderName(get(document, 'uploader', {}))
})

export default transformDocumentFromApi
