// @flow

import get from 'lodash/get'
import includes from 'lodash/includes'

import type { TApiAction } from 'modules/common/state/types'
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from 'modules/api/state/actions'

type TMetaState = {
  +error: ?string,
  +loading: boolean
}

export const initialState: TMetaState = Object.freeze({
  error: null,
  loading: false
})

const requestCounterFactory = () => {
  let requestCounter = 0

  return {
    finishRequest: () => { requestCounter = requestCounter - 1 },
    isRequesting: () => requestCounter > 0,
    startRequest: () => { requestCounter = requestCounter + 1 }
  }
}

const metaReducerFactory = (...actions: string[]) => {
  const requestCounter = requestCounterFactory()

  return (state: TMetaState = initialState, action: TApiAction) => {
    const metaType = get(action, 'meta.type')

    if (!includes(actions, metaType)) return state

    switch (action.type) {
      case REQUEST_ERROR: {
        requestCounter.finishRequest()

        return { ...state, error: action.payload || true, loading: requestCounter.isRequesting() }
      }

      case REQUEST_START: {
        requestCounter.startRequest()

        return { ...state, loading: true }
      }

      case REQUEST_SUCCESS: {
        requestCounter.finishRequest()

        return { ...state, error: null, loading: requestCounter.isRequesting() }
      }

      default: return state
    }
  }
}

export default metaReducerFactory
