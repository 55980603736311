// @flow

import { get } from 'modules/api/state/actionCreators'
import { DOCUMENT_DOWNLOAD_ALL } from '../actions'

const downloadAllDocuments = (inquiryId: string) => (
  get({
    meta: {
      endpoint: `tenders/${inquiryId}/download-all-documents`,
      type: DOCUMENT_DOWNLOAD_ALL
    }
  })
)

export default downloadAllDocuments
