// @flow

import { get } from 'modules/api/state/actionCreators'
import { SESSION_FETCH_CURRENT_USER } from '../actions'

const fetchCurrentUser = () => (
  get({
    meta: {
      endpoint: 'identity',
      type: SESSION_FETCH_CURRENT_USER
    }
  })
)

export default fetchCurrentUser
