// @flow

import * as React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import {
  Detail,
  DetailKey,
  DetailRow,
  DetailValue,
  H1,
  Paper,
  PaperCaption,
  PaperSection,
  defaultTheme
} from '@compeon-os/components'

import type { TRequiredDocument } from 'modules/inquiry/types'
import TaxAccountantModal from 'modules/taxAccountant/components/TaxAccountantModal'
import { localeFactory } from '../locales'
import { formatAmount } from '../utils'

import './Header.css'

const HighlightedText = styled.span`
  color: ${defaultTheme.primaryColor};
  cursor: pointer;
`

const productKindLocales = localeFactory('productKind')
const purposeKindLocales = localeFactory('purposeKind')
const stateLocales = localeFactory('state')

type TUser = {
  lastName: string,
  salutation: 'ms' | 'mr'
}

type THeaderProps = {
  inquiry: Object,
  requiredDocuments: TRequiredDocument[],
  currentUser: TUser,
  inquiryCreator: TUser,
  isTaxAccountant: boolean
}

type THeaderState = {
  showTaxAccountantModal: boolean
}

const TaxAccountantSalutation = styled.p`
  font-size: 1.2em;
  font-weight: bolder;
`

const salutationTranslations = {
  ms: 'Frau',
  mr: 'Herr'
}

class Header extends React.Component<THeaderProps, THeaderState> {
  state = {
    showTaxAccountantModal: false
  }

  openAccountantModal = () => this.setState({ showTaxAccountantModal: true })
  closeAccountantModal = () => this.setState({ showTaxAccountantModal: false })

  renderTaxAccountantSalutation = () => {
    const { currentUser: { salutation, lastName } } = this.props
    return (
      <React.Fragment>
        <TaxAccountantSalutation>Hallo {salutationTranslations[salutation]} {lastName},</TaxAccountantSalutation>
        <p>
          bitte stellen Sie hier die Unterlagen zur Verfügung.
        </p>
      </React.Fragment>
    )
  }

  render () {
    const { inquiry, requiredDocuments, isTaxAccountant } = this.props

    const {
      amount,
      companyName,
      productKind,
      purposeKind,
      reference,
      state
    } = inquiry
    const formattedAmount = formatAmount(amount)
    const localizedState = stateLocales(state)

    // TODO: Remove this when old tenders are fully migrated to inquiries
    const localizedProductKind = productKind ? productKindLocales(productKind) : '-'
    const localizedPurposeKind = purposeKind ? purposeKindLocales(purposeKind) : '-'

    return (
      <React.Fragment>
        <H1>Unterlagen hochladen</H1>
        <Paper>
          {isTaxAccountant && this.renderTaxAccountantSalutation()}

          <PaperCaption>Details zur Ausschreibung</PaperCaption>
          <PaperSection>
            <DetailRow>
              <Detail>
                <DetailKey>Volumen</DetailKey>
                <DetailValue>{formattedAmount}</DetailValue>
              </Detail>
              <Detail>
                <DetailKey>Verwendungszweck</DetailKey>
                <DetailValue>{localizedPurposeKind}</DetailValue>
              </Detail>
              <Detail>
                <DetailKey>Produkt</DetailKey>
                <DetailValue>{localizedProductKind}</DetailValue>
              </Detail>
            </DetailRow>
            <DetailRow>
              <Detail>
                <DetailKey>Unternehmen</DetailKey>
                <DetailValue>{companyName}</DetailValue>
              </Detail>
              <Detail>
                <DetailKey>Status</DetailKey>
                <DetailValue>{localizedState}</DetailValue>
              </Detail>
              <Detail>
                <DetailKey>Ausschreibungsnummer</DetailKey>
                <DetailValue>{reference}</DetailValue>
              </Detail>
            </DetailRow>
          </PaperSection>

          {!isTaxAccountant && (
            <PaperSection>
              <p>
                Sie können die Unterlagen entweder direkt hier hochladen, oder nach dem
                Gespräch mit einem COMPEON-Mitarbeiter diese per Mail/Fax versenden. Alternativ können Sie auch
                <HighlightedText onClick={this.openAccountantModal}> Ihren Steuerberater einladen</HighlightedText>
                , um sie hochzuladen.
              </p>

              <Modal
                className='TaxAccountantModal'
                isOpen={this.state.showTaxAccountantModal}
                overlayClassName='TaxAccountantModal__Overlay'
                bodyOpenClassName='TaxAccountantModal__Body'
              >
                <TaxAccountantModal
                  closeModal={this.closeAccountantModal}
                  inquiry={inquiry}
                  requiredDocuments={requiredDocuments}
                />
              </Modal>
            </PaperSection>
          )}
        </Paper>
      </React.Fragment>
    )
  }
}

export default Header
