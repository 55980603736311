// @flow

const FORMAT_OPTIONS = Object.freeze({
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency'
})

const formatAmount = (amount: number): string => (
  Intl.NumberFormat('de-DE', FORMAT_OPTIONS).format(amount)
)

export default formatAmount
