// @flow

const formatDocumentTitle = (title: string) => (
  title
    .trim()
    .replace(/\.(\d|\w)+$/, '')
    .replace(/\s/g, '_')
)

export default formatDocumentTitle
