// @flow

import * as React from 'react'
import { DownloadIcon } from '@compeon-os/components'
import type { TFile } from '@compeon-os/components'

const actionsForDocument = (actions: Object) => {
  const Component = (document: TFile): ?React.Element<*> => {
    const { status } = document

    if (status === 'clean' || status === 'done') {
      return (
        <DownloadIcon
          className='DownloadIcon'
          onClick={() => actions.download(document)}
          style={{ cursor: 'pointer' }}
        />
      )
    }

    return null
  }

  Component.displayName = 'DocumentActions'

  return Component
}

const addDocumentActions = (actions: Object = {}) => (document: TFile): TFile => ({
  ...document,
  actions: actionsForDocument(actions)
})

export default addDocumentActions
