// @flow

import * as React from 'react'
import styled, { css } from 'styled-components'
import { FileDropzone, FileTable, type TFile } from '@compeon-os/components'

import { prepareDocument } from '../utils'

const MAX_DOCUMENT_SIZE_IN_BYTES = 150 * 1024 * 1024

type TDocumentUploadProps = {
  addDocuments: TFile[] => TFile[],
  documents: TFile[],
  onDropRejected?: TFile[] => void,
  onUploadError?: (TFile, Error) => void,
  onUploadSuccess?: (TFile, Object) => void,
  uploadDocument: TFile => Promise<*>,
  downloadAllDocuments: void => Promise<*>,
  downloadAllDisabled?: boolean
}

const StyledFileDropzone = styled(({ large, ...props }) => <FileDropzone {...props} />)`
  height: 350px;
  margin-bottom: 24px;

  ${props => !props.large && css`
    height: 150px;
  `}
`

export const DownloadAllButton = styled.span`
  cursor: ${props => props.disabled ? 'inherit' : 'pointer'};
  text-decoration: ${props => props.disabled ? 'none' : 'underline'};
  color: ${props => props.disabled ? 'lightgrey' : 'inherit'};
`

class DocumentUpload extends React.Component<TDocumentUploadProps> {
  handleDocumentDrop = (documents: TFile[]) => {
    const preparedDocuments = documents.map(prepareDocument)
    const addedDocuments = this.props.addDocuments(preparedDocuments)

    addedDocuments.forEach(this.uploadDocument)
  }

  handleDocumentDropRejected = (documents: TFile[]) => {
    const { onDropRejected } = this.props

    // By using a timeout the upload for possible accepted files can already
    // start while the rejection handler executes a potentially blocking action.
    if (onDropRejected) setTimeout(() => onDropRejected(documents))
  }

  uploadDocument = (document: TFile) => {
    const { onUploadError, onUploadSuccess } = this.props
    let result = this.props.uploadDocument(document)

    if (onUploadSuccess) result = result.then(onUploadSuccess.bind(null, document))
    if (onUploadError) result = result.catch(onUploadError.bind(null, document))

    return result
  }

  renderDownloadAll = () => {
    const { downloadAllDocuments, downloadAllDisabled } = this.props
    const onClick = downloadAllDisabled ? null : downloadAllDocuments

    return (
      <DownloadAllButton
        disabled={downloadAllDisabled}
        onClick={onClick}
        onKeyPress={() => {}}
        role='button'
        tabIndex={-1}
      >
        Alle herunterladen
      </DownloadAllButton>
    )
  }

  render () {
    const { documents } = this.props

    return (
      <React.Fragment>
        <FileTable
          actionsHeader={this.renderDownloadAll()}
          className='Inquiry__DocumentTable'
          files={documents}
        />
        <StyledFileDropzone
          large={documents.length === 0}
          maxSize={MAX_DOCUMENT_SIZE_IN_BYTES}
          onFileDrop={this.handleDocumentDrop}
          onFileDropRejected={this.handleDocumentDropRejected}
        />
      </React.Fragment>
    )
  }
}

export default DocumentUpload
