// @flow

import { serialize } from '@compeon-os/jsonapi-serializer'

import { post } from 'modules/api/state/actionCreators'
import { TAX_ACCOUNTANT_CREATE } from '../actions'

const serializeTaxAccountant = serialize('tax-accountants', {
  relationships: {
    inquiry: {
      type: 'inquiries'
    }
  },
  attributes: [
    'firstName',
    'lastName',
    'salutation',
    'telephone',
    'email',
    'inquiry'
  ]
})

const createTaxAccountant = (data: Object) => async (dispatch: Function) => {
  const response = await dispatch(post({
    meta: {
      endpoint: 'tax-accountants',
      type: TAX_ACCOUNTANT_CREATE
    },
    payload: serializeTaxAccountant(data)
  }))

  const { email, invitationBody, invitationSubject } = response
  const emailLocation = `mailto:${email}?subject=${invitationSubject}&body=${encodeURIComponent(invitationBody)}`
  window.location.assign(emailLocation)

  return response
}

export default createTaxAccountant
