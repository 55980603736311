// @flow

import type { TFile } from '@compeon-os/components'

import { put } from 'modules/api/state/actionCreators'
import { trackDocumentProgress } from 'modules/document/utils'
import { DOCUMENT_CREATE } from '../actions'

const createDocument = (document: TFile, companyId: number, onUploadProgress: ?Function) => {
  const { originalFile, contentType, title } = document
  const encodedTitle = encodeURIComponent(title)

  return put({
    meta: {
      endpoint: 'documents',
      headers: {
        'Content-Type': contentType
      },
      jsonAPIRequest: false,
      params: {
        'company-id': companyId,
        title: encodedTitle
      },
      onUploadProgress: trackDocumentProgress(onUploadProgress),
      type: DOCUMENT_CREATE
    },
    payload: originalFile
  })
}

export default createDocument
