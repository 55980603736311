import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'

import { apiMiddleware, apiMiddlewareMock } from 'modules/api/middleware'
import { isDevelopment, isTest } from 'modules/common/utils/env'
import loggingMiddleware from 'modules/common/middleware/logging'
import { documentDataReducer } from 'modules/document/state/reducers'
import { inquiryDataReducer, inquiryMetaReducer } from 'modules/inquiry/state/reducers'
import { sessionDataReducer, sessionMetaReducer } from 'modules/session/state/reducers'

const reducers = combineReducers({
  document: combineReducers({
    data: documentDataReducer
  }),
  inquiry: combineReducers({
    data: inquiryDataReducer,
    meta: inquiryMetaReducer
  }),
  session: combineReducers({
    data: sessionDataReducer,
    meta: sessionMetaReducer
  })
})

const middleware = [
  thunk
]

if (isTest) {
  middleware.push(apiMiddlewareMock)
} else {
  middleware.push(apiMiddleware)
}

if (isDevelopment) {
  middleware.push(loggingMiddleware)
}

export default (initialState = {}) => {
  return createStore(
    reducers,
    initialState,
    applyMiddleware(...middleware)
  )
}
