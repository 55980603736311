// @flow

import type { TFile } from '@compeon-os/components'

import { formatDocumentTitle } from './'

const prepareDocument = (document: TFile): TFile => {
  const { title } = document
  const formattedTitle = formatDocumentTitle(title)

  return {
    ...document,
    status: '0 %',
    title: formattedTitle
  }
}

export default prepareDocument
