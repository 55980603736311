// @flow

import type { TDispatch } from 'modules/common/state/types'
import { REQUEST_START } from '../actions'

const apiRequest = (method: string) => (request: Object) => (dispatch: TDispatch) => (
  dispatch({
    ...request,
    meta: {
      ...request.meta,
      method
    },
    type: REQUEST_START
  })
)

export default apiRequest
