// @flow

import { post } from 'modules/api/state/actionCreators'
import { SESSION_FETCH_ACCESS_TOKEN } from '../actions'
import { refreshTokenSelector, authorizationTokenSelector } from '../selectors'
import { AUTH_URL, CLIENT_ID } from 'modules/common/utils/config'

type Toptions = {
  grantType: 'refresh_code' | 'authorization_code',
  code?: string
}

type Tpayload = {
  grant_type: 'refresh_code' | 'authorization_code',
  client_id: 'compeon-file-upload',
  code?: string,
  refresh_code?: string
}

const fetchAccessToken = (options: Toptions) =>
  (dispatch: Object => Promise<Object>, getState: void => Object) => {
    const payload: Tpayload = {
      grant_type: options.grantType,
      client_id: CLIENT_ID
    }

    if (options.code) payload.code = options.code

    if (options.grantType === 'refresh_code') {
      const refreshToken = refreshTokenSelector(getState())
      payload.refresh_code = refreshToken
    }

    if (options.grantType === 'authorization_code') {
      const authorizationToken = authorizationTokenSelector(getState())
      payload.code = authorizationToken
    }

    return dispatch(post({
      meta: {
        endpoint: `${AUTH_URL}/oauth2/token`,
        type: SESSION_FETCH_ACCESS_TOKEN,
        jsonAPIResponse: false,
        jsonAPIRequest: false,
        headers: {
          'content-type': 'application/json'
        }
      },
      payload: payload
    }))
  }

export default fetchAccessToken
