// @flow

import get from 'lodash/get'
import pick from 'lodash/pick'

const INQUIRY_ATTRIBUTES = Object.freeze([
  'amount',
  'companyId',
  'companyName',
  'documents',
  'draft',
  'id',
  'links',
  'productKind',
  'purposeKind',
  'reference',
  'state',
  'taxAccountants',
  'zipFileCreatedAt'
])

export const dataSelector = (state: Object) => {
  const denormalizedInquiry = {
    ...state.inquiry.data,
    companyId: get(state, 'inquiry.data.company.id'),
    companyName: get(state, 'inquiry.data.company.name')
  }

  return pick(denormalizedInquiry, INQUIRY_ATTRIBUTES)
}

export const linksSelector = (state: Object) => get(state, 'inquiry.data.links')

export const requiredDocumentsSelector = (state: Object) => get(state, 'inquiry.data.requiredDocuments', [])

export const inquiryCreatorSelector = (state: Object) => get(state, 'inquiry.data.user', {})
