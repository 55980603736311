// @flow

const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a')

  document.body && document.body.appendChild(link)

  link.href = url
  link.download = fileName
  link.click()

  document.body && document.body.removeChild(link)
}

export default downloadFile
