// @flow

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ErrorScreen } from 'modules/error/components'
import { withSession } from 'modules/session/components'
import InquiryPage from 'pages/inquiry'

const InquiryPageWithSession = withSession(InquiryPage)

const Router = () => (
  <Switch>
    <Route path='/inquiries/:inquiryId' component={InquiryPageWithSession} />
    <Route path='*' render={() => <ErrorScreen statusCode='404' />} />
  </Switch>
)

export default Router
