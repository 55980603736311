// @flow

import { serialize } from '@compeon-os/jsonapi-serializer'

import { DOCUMENT_CREATE_INQUIRY_RELATIONSHIP } from '../actions'
import { post } from 'modules/api/state/actionCreators'

const relationshipSerializer = serialize('documents')

const createInquiryRelationship = (documentId: string, inquiryId: string) => (
  post({
    meta: {
      endpoint: `inquiries/${inquiryId}/relationships/documents`,
      type: DOCUMENT_CREATE_INQUIRY_RELATIONSHIP
    },
    payload: relationshipSerializer([{ id: documentId }])
  })
)

export default createInquiryRelationship
