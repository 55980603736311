// @flow

import kebabCase from 'lodash/kebabCase'
import startsWith from 'lodash/startsWith'
import deepMapKeys from 'deep-map-keys'

import type { TApiAction, TStore } from 'modules/common/state/types'
import { tokenSelector } from 'modules/session/state/selectors'
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../state/actions'

const API_URL = 'https://example.com/v1'
const DEFAULT_CONFIG = Object.freeze({
  jsonAPIRequest: true,
  withCredentials: false
})

const responses = []

export const respondWith = (response: Function) => {
  responses.push(response)
}

const nextResponse = (config: Object) => {
  const response = responses.shift()

  if (!response) throw new Error('Out of queued responses.')

  return response(config)
}

export const transformRequest = [
  (data: Object) => deepMapKeys(data, kebabCase),
  JSON.stringify
]

const authorizationHeader = (token: string, externalUrl: boolean): ?Object => {
  if (!externalUrl && token) return { Authorization: `token ${token}` }
}

const requestConfig = (options: Object) => {
  const config = { ...DEFAULT_CONFIG, ...options }
  const { endpoint, headers = {}, jsonAPIRequest, jsonAPIResponse, token, ...configRest } = config
  const externalUrl = startsWith(endpoint, 'http')

  return {
    ...configRest,
    headers: {
      ...headers,
      ...(jsonAPIRequest && { 'Content-Type': 'application/json' }),
      ...authorizationHeader(token, externalUrl)
    },
    ...(jsonAPIRequest && { transformRequest }),
    url: externalUrl ? endpoint : `${API_URL}/${endpoint}`
  }
}

const requestHandler = (response = {}, meta) => ({
  meta: {
    ...meta,
    status: response.status
  },
  payload: response.data
})

const requestSuccess = (response, meta) => ({
  type: REQUEST_SUCCESS,
  ...requestHandler(response, meta)
})

const requestError = (response, meta) => ({
  type: REQUEST_ERROR,
  ...requestHandler(response, meta)
})

const apiMiddleware = ({ dispatch, getState }: TStore) => (next: Function) => async (action: TApiAction) => {
  if (action.type !== REQUEST_START) return next(action)

  const token = tokenSelector(getState())
  const config = requestConfig({ ...action.meta, token })
  const response = nextResponse(config)

  const promise = response.status < 400
    ? () => Promise.resolve(response)
    : () => Promise.reject(response)

  next(action)

  try {
    const successAction = await dispatch(requestSuccess(await promise(), action.meta))
    return successAction.payload
  } catch (error) {
    const errorAction = await dispatch(requestError(error, action.meta))
    throw errorAction.payload
  }
}

export default apiMiddleware
