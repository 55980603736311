// @flow

import get from 'lodash/get'

import { REQUEST_SUCCESS } from 'modules/api/state/actions'
import type { TApiAction } from 'modules/common/state/types'
import { INQUIRY_FETCH, REQUIRED_DOCUMENTS_FETCH } from 'modules/inquiry/state/actions'
import type { TRequiredDocument } from 'modules/inquiry/types'

type TInquiryState = {
  +amount: ?number,
  +company: {
    +id: ?string,
    +name: ?string
  },
  +id: ?string,
  +links?: Object,
  +productKind: ?string,
  +purposeKind: ?string,
  +reference: ?string,
  +requiredDocuments: TRequiredDocument[],
  +state: ?string
}

export const initialState: TInquiryState = Object.freeze({
  amount: null,
  company: {
    id: null,
    name: null
  },
  id: null,
  productKind: null,
  purposeKind: null,
  reference: null,
  requiredDocuments: [],
  state: null
})

const reducer = (state: TInquiryState = initialState, { meta, payload, type }: TApiAction) => {
  if (type !== REQUEST_SUCCESS) return state

  switch (meta.type) {
    case INQUIRY_FETCH: {
      if (get(payload, 'draft')) return state

      return {
        ...state,
        ...payload
      }
    }

    case REQUIRED_DOCUMENTS_FETCH: {
      return {
        ...state,
        requiredDocuments: payload
      }
    }

    default: return state
  }
}

export default reducer
