// @flow

import transform from 'lodash/transform'
import type { TFile } from '@compeon-os/components'

import { DOCUMENT_ADD_OR_UPDATE } from '../actions'

const transformDocuments = (documents: TFile[]) => {
  const subject = Array.isArray(documents) ? documents : [documents]

  return transform(subject, (result: Object, document: TFile) => {
    result[document.id] = document
  }, {})
}

const addOrUpdateDocuments = (documents: TFile[] | TFile) => ({
  payload: transformDocuments(documents),
  type: DOCUMENT_ADD_OR_UPDATE
})

export default addOrUpdateDocuments
