// @flow

import type { TFile } from '@compeon-os/components'

import { DOCUMENT_UPDATE_ID } from '../actions'

const updateDocumentId = (document: TFile, id: string) => ({
  payload: {
    previousId: document.id,
    nextId: id
  },
  type: DOCUMENT_UPDATE_ID
})

export default updateDocumentId
