// @flow

import get from 'lodash/get'
import includes from 'lodash/includes'

const COMPEON_EMPLOYEE_ROLES = Object.freeze([
  'admin',
  'sales_employee',
  'sales_team_lead'
])

export const authorizedSelector = (state: Object) => get(state, 'session.data.authorized')

export const compeonEmployeeSelector = (state: Object) => {
  const role = get(state, 'session.data.user.role')
  return includes(COMPEON_EMPLOYEE_ROLES, role)
}

export const firstNameSelector = (state: Object) => get(state, 'session.data.user.firstName')
export const lastNameSelector = (state: Object) => get(state, 'session.data.user.lastName')
export const currentUserSelector = (state: Object) => get(state, 'session.data.user', {})

export const fullNameSelector = (state: Object) => {
  const firstName = firstNameSelector(state)
  const lastName = lastNameSelector(state)

  return [firstName, lastName].join(' ')
}

export const tokenSelector = (state: Object) => get(state, 'session.data.token')

export const isTaxAccountantSelector = (state: Object) => Boolean(get(state, 'session.data.isTaxAccountant'))

export const refreshTokenSelector = (state: Object) => get(state, 'session.data.refreshToken')
export const authorizationTokenSelector = (state: Object) => get(state, 'session.data.authorizationToken')
