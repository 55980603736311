// @flow

import { createLogger } from 'redux-logger'

const loggingMiddleware = createLogger({
  collapsed: true,
  duration: true,
  level: 'info',
  colors: {
    title: ({ type }) => type.startsWith('REQUEST_') ? '#9E9E9E' : '#2eb398'
  }
})

export default loggingMiddleware
