// @flow

import * as React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import map from 'lodash/map'
import type { TFile } from '@compeon-os/components'
import { Button } from '@compeon-os/components'
import { uuid } from 'uuidv4'
import { withRouter } from 'react-router-dom'

import { Loading } from 'modules/common/components'
import { metaSelectorFactory } from 'modules/common/state'
import {
  addOrUpdateDocuments,
  updateDocumentId,
  updateDocumentProgress,
  updateDocumentStatus,
  downloadDocument,
  downloadAllDocuments,
  uploadDocument
} from 'modules/document/state/actionCreators'
import { fetchInquiry, fetchRequiredDocuments, pollInquiry } from 'modules/inquiry/state/actionCreators'
import { DocumentUpload } from 'modules/document/components'
import { documentsSelector } from 'modules/document/state/selectors'
import type { TDocumentProgress } from 'modules/document/types'
import { addDocumentActions, transformDocumentFromApi } from 'modules/document/utils'
import { ErrorScreen } from 'modules/error/components'
import { Header } from 'modules/inquiry/components'
import {
  dataSelector,
  linksSelector,
  requiredDocumentsSelector,
  inquiryCreatorSelector
} from 'modules/inquiry/state/selectors'
import type { TRequiredDocument } from 'modules/inquiry/types'
import { fullNameSelector, currentUserSelector, isTaxAccountantSelector } from 'modules/session/state/selectors'
import { downloadFile, redirectToUrl } from 'utils'
import Layout from 'Layout'

import './InquiryPage.css'

export const INQUIRY_POLLING_INTERVAL = 1000 * 5

export const CustomerPortalButton = styled(Button)`
  float: right;
`

type TInquiryPageProps = {
  addOrUpdateDocuments: TFile[] => Promise<*>,
  documents: TFile[],
  downloadAllDocuments: string => Promise<*>,
  downloadDocument: number => Promise<*>,
  error: boolean,
  fetchInquiry: string => Promise<*>,
  fetchRequiredDocuments: string => Promise<TRequiredDocument[]>,
  inquiry: Object,
  inquiryCreator: Object,
  inquiryLinks: Object,
  isTaxAccountant: boolean,
  loading: boolean,
  match: Object,
  pollInquiry: string => Promise<*>,
  requiredDocuments: TRequiredDocument[],
  updateDocumentId: (TFile, string) => Promise<*>,
  updateDocumentProgress: (TFile, TDocumentProgress) => Promise<*>,
  updateDocumentStatus: (TFile, string) => Promise<*>,
  uploadDocument: (TFile, number, string, ?Function) => Promise<*>,
  userFullName: string,
  currentUser: Object
}

class InquiryPage extends React.Component<TInquiryPageProps> {
  async componentDidMount () {
    this.fetchRequiredDocuments()
    await this.fetchInquiry()
    this.startInquiryPolling()
  }

  componentWillUnmount () {
    this.stopInquiryPolling()
  }

  inquiryPollingTimeout: IntervalID

  get documentsWithActions () {
    const { documents, isTaxAccountant } = this.props

    if (isTaxAccountant) return documents
    return map(documents, addDocumentActions({ download: this.downloadDocument }))
  }

  addDocuments = (documents: TFile[]) => (
    documents.map(document => {
      const id = uuid()
      const documentWithIdAndUploader = {
        ...document,
        id,
        uploaderName: this.props.userFullName
      }

      this.props.addOrUpdateDocuments(documentWithIdAndUploader)
      return documentWithIdAndUploader
    })
  )

  downloadDocument = async (document: TFile) => {
    const { location } = await this.props.downloadDocument(document.id)
    downloadFile(location, document.title)
  }

  downloadAllDocuments = async () => {
    const { location } = await this.props.downloadAllDocuments(this.props.inquiry.id)
    downloadFile(location, '')
  }

  async fetchInquiry () {
    const {
      addOrUpdateDocuments,
      fetchInquiry,
      match: {
        params: { inquiryId }
      }
    } = this.props

    try {
      const { documents, draft } = await fetchInquiry(inquiryId)

      if (!draft && documents) addOrUpdateDocuments(documents.map(transformDocumentFromApi))
    } catch (_) {}
  }

  fetchRequiredDocuments = async () => {
    const {
      match: {
        params: { inquiryId }
      },
      fetchRequiredDocuments
    } = this.props

    try {
      return await fetchRequiredDocuments(inquiryId)
    } catch (_) {}
  }

  pollInquiry = async () => {
    const {
      addOrUpdateDocuments,
      inquiry: {
        id
      },
      pollInquiry
    } = this.props

    const { documents } = await pollInquiry(id)

    if (!documents) return

    const transformedDocuments = documents.map(transformDocumentFromApi)

    addOrUpdateDocuments(transformedDocuments)
  }

  startInquiryPolling () {
    this.inquiryPollingTimeout = setInterval(this.pollInquiry, INQUIRY_POLLING_INTERVAL)
  }

  stopInquiryPolling () {
    clearInterval(this.inquiryPollingTimeout)
  }

  uploadDocument = (document: TFile) => {
    const {
      inquiry: {
        companyId,
        id: inquiryId
      },
      uploadDocument
    } = this.props

    return uploadDocument(document, companyId, inquiryId, this.handleUploadProgress(document))
  }

  handleUploadProgress = (document: TFile) => (progress: TDocumentProgress) => {
    this.props.updateDocumentProgress(document, progress)
  }

  handleUploadError = (document: TFile, error: Error) => {
    this.props.updateDocumentStatus(document, 'error')
    console.log('Error uploading ' + document.title)
    console.log(error)
    throw error
  }

  handleUploadSuccess = (document: TFile, response: Object) => {
    const { updateDocumentId, updateDocumentStatus } = this.props

    updateDocumentStatus(document, 'pending')
    updateDocumentId(document, response.id)
    console.log('Successfully uploaded ' + document.title)
    console.log(response)
  }

  handleDropRejected = (documents: TFile[]) => {
    const documentTitles = map(documents, 'title').join('\n')

    alert(
      `Folgende Dokumente entsprechen nicht den Anforderungen und können nicht
      hochgeladen werden:\n\n${documentTitles}`
    )
  }

  render () {
    const {
      error,
      currentUser,
      loading,
      inquiry,
      inquiryLinks,
      requiredDocuments,
      isTaxAccountant,
      inquiryCreator
    } = this.props

    if (loading) return <Loading message='Anfrage wird geladen' />
    if (error) return <ErrorScreen statusCode='403' />

    return (
      <Layout>
        <Header
          currentUser={currentUser}
          isTaxAccountant={isTaxAccountant}
          requiredDocuments={requiredDocuments}
          inquiryCreator={inquiryCreator}
          inquiry={inquiry}
        />

        <DocumentUpload
          addDocuments={this.addDocuments}
          documents={this.documentsWithActions}
          downloadAllDisabled={!inquiry.zipFileCreatedAt || isTaxAccountant}
          downloadAllDocuments={this.downloadAllDocuments}
          onDropRejected={this.handleDropRejected}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          uploadDocument={this.uploadDocument}
        />

        {!isTaxAccountant && (
          <CustomerPortalButton onClick={() => redirectToUrl(inquiryLinks.customerPortal)} small>
            Zur Ausschreibung
          </CustomerPortalButton>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  const { errorSelector, loadingSelector } = metaSelectorFactory('inquiry')

  return {
    documents: documentsSelector(state),
    error: errorSelector(state),
    inquiry: dataSelector(state),
    inquiryLinks: linksSelector(state),
    loading: loadingSelector(state),
    requiredDocuments: requiredDocumentsSelector(state),
    inquiryCreator: inquiryCreatorSelector(state),
    userFullName: fullNameSelector(state),
    currentUser: currentUserSelector(state),
    isTaxAccountant: isTaxAccountantSelector(state)
  }
}

const mapDispatchToProps = {
  addOrUpdateDocuments,
  downloadAllDocuments,
  downloadDocument,
  fetchInquiry,
  fetchRequiredDocuments,
  pollInquiry,
  updateDocumentId,
  updateDocumentProgress,
  updateDocumentStatus,
  uploadDocument
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InquiryPage)
