// @flow

import type { TFile } from '@compeon-os/components'

import type { TAction } from 'modules/common/state/types'
import {
  DOCUMENT_ADD_OR_UPDATE,
  DOCUMENT_UPDATE_ID,
  DOCUMENT_UPDATE_PROGRESS,
  DOCUMENT_UPDATE_STATUS
} from '../actions'

type TDocumentState = {
  +[string]: TFile
}

type TDocumentAction = {
  payload: TDocumentState | {
    id: string,
    nextId: string,
    previousId: string,
    progress: number,
    status: string
  }
} & TAction

export const initialState: TDocumentState = Object.freeze({})

const reducer = (state: TDocumentState = initialState, { type, payload }: TDocumentAction) => {
  switch (type) {
    case DOCUMENT_ADD_OR_UPDATE: {
      return {
        ...state,
        ...(payload: TDocumentState)
      }
    }
    case DOCUMENT_UPDATE_ID: {
      const { previousId, nextId } = payload
      const { [previousId]: document, ...documents } = state

      return {
        ...documents,
        [nextId]: { ...document, id: nextId }
      }
    }
    case DOCUMENT_UPDATE_PROGRESS: {
      const { id, progress } = payload

      return {
        ...state,
        [id]: {
          ...state[id],
          status: `${progress} %`
        }
      }
    }
    case DOCUMENT_UPDATE_STATUS: {
      const { id, status } = payload

      return {
        ...state,
        [id]: {
          ...state[id],
          status
        }
      }
    }
    default: return state
  }
}

export default reducer
