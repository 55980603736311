// @flow

import get from 'lodash/get'

const LOCALES = {
  productKind: {
    aval: 'Aval',
    factoring: 'Factoring',
    hire_purchase: 'Mietkauf',
    investment: 'Anlage',
    investment_loan: 'Investitionskredit',
    leasing: 'Leasing',
    loan: 'Darlehen',
    mezzanine: 'Mezzanine',
    project_financing: 'Projektfinanzierung',
    other: 'Sonstiges',
    overdraft: 'Kontokorrentkredit'
  },
  purposeKind: {
    real_estate: 'Immobilie / Grundstück',
    vehicle: '(Nutz-) Fahrzeug',
    debt_rescheduling: 'Umschuldung / Ablösung',
    goods: 'Waren / Betriebsmittel',
    machine: 'Maschine / techn. Anlage',
    guaranty: 'Bürgschaft / Garantie',
    liquidity: 'Kontokorrent / Liquidität',
    factoring: 'Factoring / Forderungsverkauf',
    other: 'Sonstiges'
  },
  state: {
    awaiting_decision: 'Entscheidung ausstehend',
    awaiting_release: 'Freischaltung ausstehend',
    awaiting_review: 'In Prüfung',
    canceled: 'Abgebrochen',
    completed: 'Abgeschlossen',
    converted_to_manual_inquiry: 'In manuelle Anfrage konvertiert',
    discarded: 'Verworfen',
    expired_without_decision: 'Ohne Entscheidung beendet',
    expired_without_offers: 'Ohne Angebot beendet',
    fulfilled: 'Abgeschlossen (Abschluss erfolgt)',
    new: 'Unvollständig',
    open: 'Offen',
    pending: 'Verifizierung ausstehend',
    unfulfilled: 'Abgeschlossen (Abschluss nicht erfolgt)'
  }
}

export const localeFactory = (path: string) => {
  const locales = get(LOCALES, path, {})
  return (key: string) => locales[key]
}
