// @flow

import { get } from 'modules/api/state/actionCreators'
import { INQUIRY_POLL } from '../actions'

const pollInquiry = (id: string) => (
  get({
    meta: {
      endpoint: `inquiries/${id}`,
      params: {
        include: 'company,documents,documents.uploader'
      },
      type: INQUIRY_POLL
    }
  })
)
export default pollInquiry
