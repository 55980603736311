// @flow

import Rollbar from 'rollbar'

import { ENV } from '../utils/env'

const ROLLBAR_TOKEN = process.env.REACT_APP_ROLLBAR_TOKEN
const rollbarConfig = {
  accessToken: ROLLBAR_TOKEN,
  enabled: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true
      }
    },
    environment: ENV
  }
}

if (ROLLBAR_TOKEN) Rollbar.init(rollbarConfig)
