// @flow

const NODE_ENV = process.env.NODE_ENV || 'development'
const BUILD_BRANCH = process.env.REACT_APP_BUILD_BRANCH

const getEnv = (nodeEnv: string, buildBranch: ?string) => {
  if (nodeEnv === 'test') return 'test'

  switch (buildBranch) {
    case 'main': return 'production'
    case 'development': return 'staging'
    default: return nodeEnv
  }
}
export const ENV = getEnv(NODE_ENV, BUILD_BRANCH)
const isEnv = (env: string) => ENV === env

export const isTest = isEnv('test')
export const isDevelopment = isEnv('development')
export const isProduction = isEnv('production')
export const isStaging = isEnv('staging')
export const isProductionLike = isProduction || isStaging
