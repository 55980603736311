import startsWith from 'lodash/startsWith'

function validateTelephone (telephone) {
  const validFirstCharacter = startsWith(telephone, '0') || startsWith(telephone, '+')

  if (!validFirstCharacter || telephone.length < 6) {
    return 'Bitte geben Sie eine gültige Mobilfunknummer an.'
  }
}

export default validateTelephone
