// @flow

import { get } from 'modules/api/state/actionCreators'
import { SESSION_FETCH } from '../actions'

const fetchSession = () => (
  get({
    meta: {
      endpoint: 'session/token',
      params: {
        include: 'user'
      },
      type: SESSION_FETCH,
      withCredentials: true
    }
  })
)

export default fetchSession
