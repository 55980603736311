import React from 'react'
import { every, orderBy } from 'lodash'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  CheckIcon,
  CloseIcon,
  TextInput,
  Button,
  NumberInput,
  Select,
  FormLabel,
  Grid,
  Column,
  Row
} from '@compeon-os/components'

import type { TRequiredDocument } from 'modules/inquiry/types'
import RequiredDocuments from 'modules/inquiry/components/RequiredDocuments'
import createTaxAccountant from 'modules/taxAccountant/state/actionCreators/createTaxAccountant'
import validateEmail from 'utils/validateEmail'
import validateTelephone from 'utils/validateTelephone'

import './TaxAccountantModal.css'

const salutationOptions = [
  { value: null, label: '' },
  { value: 'mr', label: 'Herr' },
  { value: 'ms', label: 'Frau' }
]

const validations = {
  salutation: value => value ? null : 'Bitte geben Sie die Anrede Ihres Steuerberaters an',
  firstName: () => null,
  lastName: value => value ? null : 'Bitte geben Sie den Nachnamen Ihres Steuerberaters an',
  telephone: value => value ? validateTelephone(value) : 'Bitte geben Sie die Telefonnummer Ihres Steuerberaters an',
  email: value => value ? validateEmail(value) : 'Bitte geben sie die E-Mail-Adresse Ihres Steuerberaters an'
}

type TTaxAccountantModalProps = {
  closeModal: void => void,
  createTaxAccountant: Object => Promise<*>,
  inquiry: Object,
  requiredDocuments: TRequiredDocument[]
}

type TTaxAccountantModalState = {
  values: {
    salutation: string,
    firstName: string,
    lastName: string,
    telephone: string,
    email: string
  },
  touched: {
    salutation: boolean,
    firstName: boolean,
    lastName: boolean,
    telephone: boolean,
    email: boolean
  }
}

const getCreatedAt = taxAccountant => new Date(taxAccountant.createdAt)

function selectTaxAccountant ({ taxAccountants }) {
  if (!taxAccountants) return null
  return orderBy(taxAccountants, getCreatedAt, 'desc')[0]
}

export const SubmitButton = styled(Button)`
  width: 100%;
`

export class TaxAccountantModal extends React.Component<TTaxAccountantModalProps, TTaxAccountantModalState> {
  constructor (props) {
    super(props)

    const { salutation, firstName, lastName, telephone, email } = this.taxAccountant || {}

    this.state = {
      error: false,
      isLoading: false,
      values: {
        salutation: salutation || '',
        firstName: firstName || '',
        lastName: lastName || '',
        telephone: telephone || '',
        email: email || ''
      },
      touched: {
        salutation: Boolean(salutation),
        firstName: Boolean(firstName),
        lastName: Boolean(lastName),
        telephone: Boolean(telephone),
        email: Boolean(email)
      }
    }
  }

  get taxAccountant () {
    return selectTaxAccountant(this.props.inquiry)
  }

  handleChange = field => ({ target: { value } }) => {
    this.setState(({ values }) => ({ values: { ...values, [field]: value } }))
  }

  touchField = field => () => {
    this.setState(({ touched }) => ({ touched: { ...touched, [field]: true } }))
  }

  touchAll = () => {
    this.setState({
      touched: {
        salutation: true,
        firstName: true,
        lastName: true,
        telephone: true,
        email: true
      }
    })
  }

  validate = field => {
    const validateFunc = validations[field]
    const validationResult = validateFunc(this.state.values[field])

    return validationResult ? (this.state.touched[field] && validationResult) : null
  }

  handleSubmit = async () => {
    const { createTaxAccountant, inquiry, closeModal } = this.props
    const { values } = this.state

    const isValid = every(values, (value, field) => {
      const validation = validations[field]
      return !validation(value)
    })

    if (!isValid) return this.touchAll()

    this.setState({ error: false, isLoading: true })

    try {
      await createTaxAccountant({ ...values, inquiry: { id: inquiry.id } })
      closeModal()
    } catch (_) {
      this.setState({ error: true })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  renderStatus = () => {
    const { error, isLoading } = this.state

    if (isLoading) {
      return (
        <span className='TaxAccountantModal__Status'>
          Wird eingeladen ...
        </span>
      )
    } else if (error) {
      return (
        <span className='TaxAccountantModal__Status TaxAccountantModal__Status--error'>
          <CloseIcon size={16} color='#fa5400' className='TaxAccountantModal__Status__Icon' />
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
        </span>
      )
    } else if (this.taxAccountant) {
      return (
        <span className='TaxAccountantModal__Status TaxAccountantModal__Status--success'>
          <CheckIcon size={16} color='#84b922' className='TaxAccountantModal__Status__Icon' />
          E-Mail erfolgreich versendet.
        </span>
      )
    }
  }

  render () {
    const { closeModal, requiredDocuments } = this.props
    const {
      isLoading,
      values: {
        email,
        firstName,
        lastName,
        salutation,
        telephone
      }
    } = this.state

    const submitButtonText = this.taxAccountant ? 'Einladung erneut versenden' : 'Einladung versenden'

    return (
      <Grid>
        <Row className='TaxAccountantModal__HeaderRow'>
          <Column size={{ xs: 10 }}>
            <h2>Steuerberater einladen</h2>
          </Column>
          <Column className='TaxAccountantModal__Close' size={{ xs: 2 }}>
            <CloseIcon color='black' onClick={closeModal} className='TaxAccountantModal__Close__Icon'/>
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12 }}>
            <p>Für die weitere Bearbeitung benötigen unsere Finanzierungspartner noch folgende Unterlagen:</p>

            <RequiredDocuments requiredDocuments={requiredDocuments} />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12 }}>
            <h4>Kontaktdaten Ihres Steuerberaters</h4>
          </Column>
        </Row>
        <Row>
          <Column size={{ xs: 12, md: 4 }}>
            <FormLabel>
              Anrede
            </FormLabel>
            <Select
              name='salutation'
              error={this.validate('salutation')}
              onChange={this.handleChange('salutation')}
              onBlur={this.touchField('salutation')}
              value={salutation}
              options={salutationOptions}
            />
          </Column>

          <Column size={{ xs: 12, md: 4 }}>
            <FormLabel>
              Vorname
            </FormLabel>
            <TextInput
              name='firstName'
              error={this.validate('firstName')}
              onChange={this.handleChange('firstName')}
              onBlur={this.touchField('firstName')}
              value={firstName}
            />
          </Column>

          <Column size={{ xs: 12, md: 4 }}>
            <FormLabel>
              Nachname
            </FormLabel>
            <TextInput
              name='lastName'
              error={this.validate('lastName')}
              onChange={this.handleChange('lastName')}
              onBlur={this.touchField('lastName')}
              value={lastName}
            />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12 }}>
            <FormLabel>
              Telefonnummer
            </FormLabel>
            <NumberInput
              name='telephone'
              error={this.validate('telephone')}
              onChange={this.handleChange('telephone')}
              onBlur={this.touchField('telephone')}
              value={telephone}
            />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12 }}>
            <FormLabel>
              E-Mail
            </FormLabel>
            <TextInput
              name='email'
              error={this.validate('email')}
              onChange={this.handleChange('email')}
              onBlur={this.touchField('email')}
              value={email}
            />
          </Column>
        </Row>

        <Row>
          <Column size={{ xs: 12 }}>
            {this.renderStatus()}
          </Column>
        </Row>

        <Row>
          <Column offset={{ lg: 6 }} size={{ xs: 12, lg: 6 }}>
            <SubmitButton disabled={isLoading} onClick={this.handleSubmit} small>
              {submitButtonText}
            </SubmitButton>
          </Column>
        </Row>
      </Grid>
    )
  }
}

const mapDispatchToProps = {
  createTaxAccountant
}

export default connect(null, mapDispatchToProps)(TaxAccountantModal)
