// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import { Loading } from 'modules/common/components'
import { metaSelectorFactory } from 'modules/common/state'
import { ErrorScreen } from 'modules/error/components'
import { authorizedSelector } from '../state/selectors'
import { fetchSession, fetchTaxAccountantToken, fetchAccessToken, fetchCurrentUser } from '../state/actionCreators'

type TSessionWrapperProps = {
  authorized: boolean,
  children: React.ChildrenArray<*>,
  fetchSession: () => Promise<*>,
  fetchTaxAccountantToken: string => Promise<Object>,
  fetchAccessToken: Object => Promise<Object>,
  fetchCurrentUser: void => Promise<Object>,
  location: Object,
  loading: boolean
}

type TgrantType = 'refresh_code' | 'authorization_code'

const extractQueryParams = (queryString = '') => {
  const queryParams = queryString.slice(1).split('&')
  return queryParams.reduce((acc, param) => {
    const [key, value] = param.split('=')
    acc[key] = value

    return acc
  }, {})
}

export const withSession = (Component: React.ComponentType<Object>) => {
  const WithSession = (props: Object) => (
    <ConnectedSessionWrapper {...props}>
      <Component {...props} />
    </ConnectedSessionWrapper>
  )

  return WithSession
}

export class SessionWrapper extends React.Component<TSessionWrapperProps> {
  async componentDidMount () {
    try {
      if (this.taxAccountantToken) {
        await this.props.fetchTaxAccountantToken(this.taxAccountantToken)

        await this.scheduleTokenRefresh('authorization_code')

        this.props.fetchCurrentUser()
      } else {
        await this.props.fetchSession()
      }
    } catch (_) {}
  }

  componentWillUnmount () {
    clearTimeout(this.refreshTokenTimeout)
  }

  refreshTokenTimeout: TimeoutID

  get taxAccountantToken () {
    const { location } = this.props
    return extractQueryParams(location.search)['tax-accountant-token']
  }

  fetchAccessToken = (grantType: TgrantType) => this.props.fetchAccessToken({ grantType })

  scheduleTokenRefresh = async (grantType: TgrantType): Promise<void> => {
    clearTimeout(this.refreshTokenTimeout)

    const response = await this.fetchAccessToken(grantType)

    const timeout = 1000 * (response.expires_in - 10)

    this.refreshTokenTimeout = setTimeout(() => this.scheduleTokenRefresh('refresh_code'), timeout)
  }

  render () {
    const { authorized, children, loading } = this.props

    if (loading) return <Loading message='Anmelden' />
    if (!authorized) return <ErrorScreen statusCode='403' />

    return children
  }
}

const mapStateToProps = state => {
  const { loadingSelector } = metaSelectorFactory('session')

  return {
    authorized: authorizedSelector(state),
    loading: loadingSelector(state)
  }
}

const mapDispatchToProps = {
  fetchSession,
  fetchTaxAccountantToken,
  fetchAccessToken,
  fetchCurrentUser
}

const ConnectedSessionWrapper = connect(mapStateToProps, mapDispatchToProps)(SessionWrapper)

export default ConnectedSessionWrapper
