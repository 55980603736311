// @flow

import axios from 'axios'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import startsWith from 'lodash/startsWith'
import deepMapKeys from 'deep-map-keys'
import { deserialize } from '@compeon-os/jsonapi-serializer'

import type { TApiAction, TStore } from 'modules/common/state/types'
import { isProduction } from 'modules/common/utils/env'
import { tokenSelector } from 'modules/session/state/selectors'
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../state/actions'

const API_URL = isProduction ? 'https://api.compeon.de/v1' : 'https://api.staging.compeon.de/v1'
const DEFAULT_CONFIG = Object.freeze({
  jsonAPIRequest: true,
  jsonAPIResponse: true,
  withCredentials: false
})

export const transformResponse = [
  JSON.parse,
  (data: Object) => deepMapKeys(data, camelCase),
  deserialize()
]

export const transformRequest = [
  (data: Object) => deepMapKeys(data, kebabCase),
  JSON.stringify
]

const requestConfig = (options: Object) => {
  const config = { ...DEFAULT_CONFIG, ...options }
  const { endpoint, jsonAPIRequest, jsonAPIResponse, token, ...configRest } = config
  const externalUrl = startsWith(endpoint, 'http')

  const headers = { ...config.headers }

  if (!externalUrl && token) headers.Authorization = `token ${token}`

  if (jsonAPIRequest) {
    headers['Content-Type'] = 'application/json'
  }

  return {
    ...configRest,
    headers,
    ...(jsonAPIRequest && { transformRequest }),
    ...(jsonAPIResponse && { transformResponse }),
    url: externalUrl ? endpoint : `${API_URL}/${endpoint}`
  }
}

const requestHandler = (response = {}, meta) => ({
  meta: {
    ...meta,
    status: response.status
  },
  payload: response.data
})

const requestSuccess = (response, meta) => ({
  type: REQUEST_SUCCESS,
  ...requestHandler(response, meta)
})

const requestError = (response, meta) => ({
  type: REQUEST_ERROR,
  ...requestHandler(response, meta)
})

const apiMiddleware = ({ dispatch, getState }: TStore) => (next: Function) => async (action: TApiAction) => {
  if (action.type !== REQUEST_START) return next(action)

  const state = getState()

  const token = tokenSelector(state)

  const { method, url, ...config } = requestConfig({ ...action.meta, token })

  try {
    const response = method === 'get'
      ? axios.get(url, config)
      : axios[method](url, action.payload, config)

    next(action)

    const successAction = await dispatch(requestSuccess(await response, action.meta))
    return successAction.payload
  } catch (error) {
    const errorAction = await dispatch(requestError(error.response, action.meta))
    throw errorAction.payload
  }
}

export default apiMiddleware
