// @flow

import { get } from 'modules/api/state/actionCreators'
import { DOCUMENT_DOWNLOAD } from '../actions'

const downloadDocument = (documentId: number) => (
  get({
    meta: {
      endpoint: `company-documents/${documentId}/download`,
      type: DOCUMENT_DOWNLOAD
    }
  })
)

export default downloadDocument
