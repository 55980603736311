// @flow

import { get } from 'modules/api/state/actionCreators'
import { REQUIRED_DOCUMENTS_FETCH } from '../actions'

const fetchRequiredDocuments = (id: string) => (
  get({
    meta: {
      endpoint: `inquiries/${id}/required-documents`,
      type: REQUIRED_DOCUMENTS_FETCH
    }
  })
)

export default fetchRequiredDocuments
