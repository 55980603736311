// @flow

import type { TFile } from '@compeon-os/components'

import type { TDispatch } from 'modules/common/state/types'
import { createDocument, createInquiryRelationship } from './'

const uploadDocument = (
  document: TFile,
  companyId: number,
  inquiryId: string,
  onUploadProgress: Function
) => async (dispatch: TDispatch) => {
  const createdDocument = await dispatch(createDocument(document, companyId, onUploadProgress))
  await dispatch(createInquiryRelationship(createdDocument.id, inquiryId))

  return createdDocument
}

export default uploadDocument
