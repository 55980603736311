// @flow

import { get } from 'modules/api/state/actionCreators'
import { INQUIRY_FETCH } from '../actions'

const fetchInquiry = (id: string) => (
  get({
    meta: {
      endpoint: `inquiries/${id}`,
      params: {
        include: 'company,documents,documents.uploader,tax-accountants'
      },
      type: INQUIRY_FETCH
    }
  })
)

export default fetchInquiry
