// @flow

import get from 'lodash/get'

const metaSelectorFactory = (key: string) => ({
  errorSelector: (state: Object) => get(state, `${key}.meta.error`),
  loadingSelector: (state: Object) => get(state, `${key}.meta.loading`)
})

export default metaSelectorFactory
