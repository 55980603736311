// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import {
  Column,
  Footer,
  Grid,
  Header,
  MenuDivider,
  MenuItem,
  Row
} from '@compeon-os/components'

import { isProduction } from 'modules/common/utils/env'
import { fullNameSelector, isTaxAccountantSelector } from 'modules/session/state/selectors'

const DASHBOARD_URL = isProduction ? 'https://app.compeon.de' : 'https://app.staging.compeon.de'

type TLayoutProps = {
  children: React.ChildrenArray<*>,
  fullName: string,
  isTaxAccountant: boolean
}

class Layout extends React.Component<TLayoutProps> {
  renderMenu = () => (
    <React.Fragment>
      <MenuItem className='Menu__Heading'>{this.props.fullName}</MenuItem>
      <MenuDivider />
      <MenuItem>
        <a className='Menu__Link' href={DASHBOARD_URL} rel='noopener noreferrer' target='_blank'>Dashboard</a>
      </MenuItem>
    </React.Fragment>
  )

  render () {
    const { children, fullName, isTaxAccountant } = this.props

    return (
      <React.Fragment>
        <Header hideUserInformation={isTaxAccountant} userName={fullName} renderMenu={this.renderMenu} />
        <Grid className='App__Container'>
          <Row>
            <Column size={{ xs: 12 }}>
              {children}
            </Column>
          </Row>
        </Grid>
        <Footer showPartners />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  fullName: fullNameSelector(state),
  isTaxAccountant: isTaxAccountantSelector(state)
})

export default connect(mapStateToProps)(Layout)
