
// @flow

import { post } from 'modules/api/state/actionCreators'
import { SESSION_FETCH_TAX_ACCOUNTANT_TOKEN } from '../actions'
import { AUTH_URL, CLIENT_ID } from 'modules/common/utils/config'

const fetchTaxAccountantToken = (token: string) => (
  post({
    meta: {
      endpoint: `${AUTH_URL}/presigned-login`,
      type: SESSION_FETCH_TAX_ACCOUNTANT_TOKEN,
      jsonAPIResponse: false,
      jsonAPIRequest: false
    },
    payload: {
      code: token,
      client_id: CLIENT_ID
    }
  })
)

export default fetchTaxAccountantToken
