// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Button, CompeonIcon, Footer } from '@compeon-os/components'

import { isProduction } from 'modules/common/utils/env'
import {
  authorizedSelector,
  compeonEmployeeSelector,
  firstNameSelector
} from 'modules/session/state/selectors'

import './ErrorScreen.css'

type TErrorScreenProps = {
  authorized: boolean,
  compeonEmployee: boolean,
  exception?: boolean,
  firstName: ?string,
  statusCode?: string
}

const LOGIN_URL = isProduction
  ? 'https://app.compeon.de/users/sign_in'
  : 'https://app.staging.compeon.de/users/sign_in'

const reloadPage = () => window.location.reload()

const ErrorHeader = ({ statusCode }) => {
  switch (statusCode) {
    case '404': return 'Sieht so aus, als hätten Sie sich verlaufen.'
    case '403': return 'Sieht so aus, als wären Sie nicht autorisiert.'
    case '500':
    default: return 'Sieht so aus, als wäre etwas schief gelaufen.'
  }
}

const ErrorMessage = ({ compeonEmployee, firstName, statusCode }) => {
  switch (statusCode) {
    case '404': return 'Die von Ihnen aufgerufene Seite existiert nicht.'
    case '403':
      if (compeonEmployee && firstName) {
        return `Hallo ${firstName}. Bitte benutze Sales, um Unterlagen zu Ausschreibungen hochzuladen.`
      }

      return 'Sie besitzen nicht die Berechtigungen, um diese Seite aufzurufen.'
    case '500':
    default: return 'Es liegt ein unbekanntes Problem vor.'
  }
}

export class ErrorScreen extends React.Component<TErrorScreenProps> {
  static defaultProps = {
    statusCode: '500'
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    const { authorized, compeonEmployee, exception, firstName, statusCode } = this.props

    return (
      <>
        <div className='ErrorScreen'>
          <h1 className='ErrorScreen__Header'>
            <ErrorHeader statusCode={statusCode} />
          </h1>

          {statusCode === '404' &&
            <span className='ErrorScreen__StatusCode'>
              4
              <CompeonIcon className='ErrorScreen__StatusCode__C' size={110} color='white' />
              4
            </span>
          }

          <h4 className='ErrorScreen__Message'>
            <ErrorMessage
              compeonEmployee={compeonEmployee}
              firstName={firstName}
              statusCode={statusCode}
            />
          </h4>

          {exception &&
            <Button className='ErrorScreen__Button' onClick={reloadPage} phantom>
              Diese Seite neu laden
            </Button>
          }
          {!exception && authorized === false && (
            <a href={LOGIN_URL}>
              <Button className='ErrorScreen__Button' phantom>
                Anmelden
              </Button>
            </a>
          )}
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  authorized: authorizedSelector(state),
  compeonEmployee: compeonEmployeeSelector(state),
  firstName: firstNameSelector(state)
})

export default connect(mapStateToProps)(ErrorScreen)
