// @flow

import type { TDocumentProgress } from '../types'

type TProgressCallback = TDocumentProgress => void

const trackDocumentProgress = (onProgress: ?TProgressCallback) => (event: ProgressEvent) => {
  if (!onProgress) return

  const bytesTotal = event.total
  const bytesUploaded = event.loaded

  const progress = {
    bytesTotal,
    bytesUploaded,
    progress: Math.floor(bytesUploaded * 100 / bytesTotal)
  }

  onProgress(progress)
}

export default trackDocumentProgress
